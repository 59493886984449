import { createSlice } from "@reduxjs/toolkit";

const registrationDetailsSlice = createSlice({
  name: "registrationDetailsSlice",
  initialState: {
    registrationDetails: null,
    user: null,
    otpVerificationKey: "",
    bhetDaanDetails: null,
    isOtpVerified: false,
    page: 1,
  },
  reducers: {
    changePage: (state, action) => {
      state.page = action.payload.page;
    },

    addRegistrationDetails: (state, action) => {
      // console.log("addRegistrationDetails action.payload", action.payload);
      state.registrationDetails = action.payload.registrationDetails;

      const userObj = {
        name: action.payload.user.name,
        contact: action.payload.user.UserDetail.contact,
        offeringName: action.payload.user.UserDetail.offeringName,
        gotra: action.payload.user.UserDetail.gotra,
        isPrasad: null,
        address: null,
      };
      if (action.payload.user.UserAddress) {
        let addressTemp = action.payload.user.UserAddress.address ?? "";
        let commaIndex = addressTemp.indexOf(",");
        let floor = addressTemp.substring(0, commaIndex).trim();
        let road = addressTemp.substring(commaIndex + 1).trim();
        userObj.address = {
          buildingName: floor,
          roadName: road,
          city: action.payload.user.UserAddress.city,
          state: action.payload.user.UserAddress.state,
          pincode: action.payload.user.UserAddress.pin,
          country: action.payload.user.UserAddress.country,
        };
      }
      state.user = userObj;
      let bhetDetails = action.payload.bhetDaanDetails;
      if (bhetDetails) {
        let finalBhetDetails = {};
        let generalBhetDetails = bhetDetails["generalBhetDaanSubEvents"];
        let specificBhetDetails = bhetDetails["specificBhetDaanSubEvents"];
        let mainPrasadSubEvents = bhetDetails["mainPrasadSubEvents"];

        if (generalBhetDetails && generalBhetDetails.length > 0) {
          generalBhetDetails = generalBhetDetails.sort(
            (a, b) => (b.isMostOffered ? 1 : -1) - (a.isMostOffered ? 1 : -1)
          );
        }
        // specificBhetDetails = specificBhetDetails.sort((a, b) => b.isMostOffered - a.isMostOffered);

        if (specificBhetDetails && specificBhetDetails.length > 0) {
          specificBhetDetails = specificBhetDetails.sort(
            (a, b) => (b.isMostOffered ? 1 : -1) - (a.isMostOffered ? 1 : -1)
          );
        }


        finalBhetDetails = {
          generalBhetDaanSubEvents: generalBhetDetails,
          specificBhetDaanSubEvents: specificBhetDetails,
          mainPrasadSubEvents: mainPrasadSubEvents,
        };
        state.bhetDaanDetails = finalBhetDetails;
      } else {
        state.bhetDaanDetails = action.payload.bhetDaanDetails;
      }
      state.page = action.payload.page;
    },

    addUserEditDetails: (state, action) => {
      const userObj = {
        name: action.payload.user.name,
        contact: action.payload.user.contact,
        offeringName: action.payload.user.offeringName,
        gotra: action.payload.user.gotra,
        isPrasad: action.payload.user.isPrasad,
        notes: action.payload.user.notes,
        address: null,
      };
      if (action.payload.user.UserAddress) {
        let addressTemp = action.payload.user.UserAddress.address ?? "";
        let commaIndex = addressTemp.indexOf(",");
        let floor = addressTemp.substring(0, commaIndex).trim();
        let road = addressTemp.substring(commaIndex + 1).trim();
        userObj.address = {
          buildingName: floor,
          roadName: road,
          city: action.payload.user.UserAddress.city,
          state: action.payload.user.UserAddress.state,
          pincode: action.payload.user.UserAddress.pin,
          country: action.payload.user.UserAddress.country,
        };
      }
      state.registrationDetails = action.payload.registrationDetails;
      state.user = userObj;
      state.page = action.payload.page;
    },

    deleteBhetDaanDetails: (state, action) => {
      state.bhetDaanDetails = null;
      state.page = 3;
    },

    deleteRegistrationDetails: (state, action) => {
      state.registrationDetails = null;
      state.user = null;
      state.otpVerificationKey = null;
      state.bhetDaanDetails = null;
      state.isOtpVerified = false;
      state.page = 1;
    },

    setOTPVerificationKey: (state, action) => {
      state.otpVerificationKey = action.payload;
    },

    setIsOtpVerified: (state, action) => {
      state.isOtpVerified = action.payload;
    },
  },
});

export const {
  changePage,
  addRegistrationDetails,
  deleteRegistrationDetails,
  setOTPVerificationKey,
  addUserEditDetails,
  setIsOtpVerified,
} = registrationDetailsSlice.actions;
export default registrationDetailsSlice.reducer;
